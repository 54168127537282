body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading-container {
  text-align: center;
}

.full-loading-container {
  height: 100vh;
  display: inline-block;
  width: 100%;
  background-color: gray;
  text-align: center;
  padding: 40px;
}

.lds-dual-ring {
  display: inline-block;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pg-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.arrow-back {
  padding: 2px 2px 2px 6px;
  margin-right: 10px;
  background: #ebebeb;
  border-radius: 50%;
  color: #727273;
  font-size: 24px;
  cursor: pointer;
}

.frm .MuiFormLabel-asterisk { 
  color: red; 
}

.frm .Mui-readOnly {
  opacity: 0.7;
  pointer-events: none;
}

.mr5 {
  margin-right: 10px;
}

.tabs-custom .MuiButtonBase-root {
  padding: 15px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
}
